export const TASK_STATUS_LIST = [
  { id: 0, name: '未开始' },
  { id: 1, name: '进行中' },
  { id: 2, name: '已完成' },
  { id: 3, name: '失败' },
];

export const TASK_STATUS_MAP = {
  NO_START: 0,
  PENDING: 1,
  FINISH: 2,
  FAIL: 3,
};

export const STORY_TYPE_MAP = {
  substance: '汽车干货',
  kol: '车主种草',
  pickup: '车主提车',
  other: '经销商种草',
};

export const TASK_COLUMNS = [
  // {
  //   title: "序号",
  //   dataIndex: "index",
  // },
  {
    title: '品牌',
    dataIndex: 'principalName',
    scopedSlots: { customRender: 'principalName' },
  },
  {
    title: '车系',
    dataIndex: 'seriesName',
    scopedSlots: { customRender: 'seriesName' },
  },
  {
    title: '卖点',
    dataIndex: 'features',
    scopedSlots: { customRender: 'features' },
  },
  {
    title: '故事类型',
    dataIndex: 'storyType',
    scopedSlots: { customRender: 'storyType' },
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '创建时间',
    dataIndex: 'ctime',
    width: 250,
    scopedSlots: { customRender: 'ctime' },
  },
  {
    title: '完成时间',
    dataIndex: 'finishTime',
    scopedSlots: { customRender: 'finishTime' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 130,
    scopedSlots: { customRender: 'action' },
  },
];

export const SELL_COLUMNS = [
  {
    title: '品牌',
    dataIndex: 'principalId',
    scopedSlots: { customRender: 'principalId' },
  },
  {
    title: '车系',
    dataIndex: 'seriesId',
    scopedSlots: { customRender: 'seriesId' },
  },
  {
    title: '职业',
    dataIndex: 'occupation',
    scopedSlots: { customRender: 'occupation' },
  },
  {
    title: '卖点',
    dataIndex: 'featureCode',
    scopedSlots: { customRender: 'featureCode' },
  },
  {
    title: '具体卖点',
    dataIndex: 'thdFeature',
    scopedSlots: { customRender: 'thdFeature' },
  },
  {
    title: '卖点描述',
    dataIndex: 'featureDesc',
    scopedSlots: { customRender: 'featureDesc' },
    maxWidth: 500,
  },
  {
    title: '添加时间',
    dataIndex: 'ctime',
    scopedSlots: { customRender: 'ctime' },
  },
];

export const ARTICLE_COLUMNS = [
  // {
  //   title: "序号",
  //   dataIndex: "",
  //   width: 220,
  // },
  {
    title: '品牌',
    dataIndex: 'taskPrincipalId',
    scopedSlots: { customRender: 'taskPrincipalId' },
    width: 100,
  },
  {
    title: '车系',
    dataIndex: 'taskSeriesId',
    scopedSlots: { customRender: 'taskSeriesId' },
    width: 100,
  },
  {
    title: '故事类型',
    dataIndex: 'taskStoryType',
    scopedSlots: { customRender: 'taskStoryType' },
    width: 110,
  },
  {
    title: '故事',
    dataIndex: 'storyContent',
    scopedSlots: { customRender: 'storyContent' },
    width: 220,
  },
  {
    title: '笔记',
    dataIndex: 'note',
    scopedSlots: { customRender: 'note' },
    width: 220,
  },
  {
    title: '是否可用',
    dataIndex: 'isUsed',
    scopedSlots: { customRender: 'isUsed' },
    width: 100,
  },
  {
    title: '创建时间',
    dataIndex: 'ctime',
    width: 150,
    scopedSlots: { customRender: 'ctime' },
  },
  {
    title: '创建人',
    dataIndex: 'creator',
    scopedSlots: { customRender: 'creator' },
    width: 100,
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 100,
  },
];

export const EMOJI_LIST = [
  {
    title: '常用',
    type: 'emoji',
    // 个别表情为组合表情, 需要把每个表情作为单独元素
    content: [
      '📅',
      '🎁',
      '🚗',
      '🉐',
      '📞',
      '🔍',
      '📣',
      '🆕',
      '🔥',
      '🉑',
      '0️⃣',
      '1️⃣',
      '2️⃣',
      '3️⃣',
      '4️⃣',
      '5️⃣',
      '6️⃣',
      '7️⃣',
      '8️⃣',
      '9️⃣',
      '🔟',
      '‼',
      '➕',
      '➖',
      '📩',
      '💰',
      '🈷',
      '👌',
      '✅',
      '❤',
      '🈶',
      '💥',
      '✨',
      '⁉',
      '🍠',
      '🙋🏻‍♂️',
      '🙋🏻‍♀️',
      '❤',
      '🚫',
      '💯',
      '🔆',
      '🧣',
      '👀',
      '🌿',
      '☘',
      '🍀',
      '🌈',
      '⭐',
      '🌟',
      '✨',
      '🔥',
      '💥',
      '🌝',
      '❄',
      '💦',
      '💧',
      '✈',
      '🌇',
      '🏙',
      '🌄',
      '☎',
      '📞',
      '💰',
      '✉',
      '💌',
      '📩',
    ],
  },
  {
    title: '基础',
    type: 'emoji',
    content:
      '😀😃😄😁😆😅🤣😂🙂🙃🫠😉😊😇🥰😍🤩😘😗😚😙🥲😋😛😜🤪😝🤑🤗🤭🫢🫣🤫🤔🫡🤐🤨😐😑😶🫥😶‍🌫️😏😒🙄😬😮‍💨🤥😌😔😪🤤😴😷🤒🤕🤢🤮🤧🥵🥶🥴😵😵‍💫🤯🤠🥳🥸😎🤓🧐😕🫤😟🙁😮😯😲😳🥺🥹😦😧😨😰😥😢😭😱😖😣😞😓😩😫🥱😤😡😠🤬😈👿💀☠️💩🤡👹👺👻👽👾🤖😺😸😹😻😼😽🙀😿😾🙈🙉🙊💋💌💘💝💖💗💓💞💕💟❣️💔❤️‍🔥❤️‍🩹❤️🧡💛💚💙💜🤎🖤🤍💯💢💥💫💦💨🕳️💣💬👁️‍🗨️🗨️🗯️💭💤♈♉♊♋♌♍♎♏♐♑♒♓',
  },
  {
    title: '手势',
    type: 'emoji',
    content:
      '👋🤚🖐️✋🖖🫱🫲🫳🫴👌🤌🤏✌️🤞🫰🤟🤘🤙👈👉👆🖕👇☝️🫵👍👎✊👊🤛🤜👏🙌🫶👐🤲🤝🙏✍️💅🤳💪🦾🦿🦵🦶👂🦻👃🧠🫀🫁🦷🦴👀👁️👅👄🫦👹👺💀👻👽👦👧👨👩👴👵👶👱👮👲👳👷👸💂🎅👰👼💆💇🙍🙎🙅🙆💁🙋🙇🙌🙏👤👥🚶🏃👯💃👫👬👭💏💑👪',
  },
  {
    title: '旅行',
    type: 'emoji',
    content:
      '🚂🚃🚄🚅🚆🚇🚈🚉🚊🚝🚞🚋🚌🚍🚎🚐🚑🚒🚓🚔🚕🚖🚗🚘🚙🛻🚚🚛🚜🏎🏍🛵🦽🦼🛺🚲🛴🌍🌎🌏🌐🗺🗾🧭🏔⛰🌋🗻🏕🏖🏜🏝🏞🏟🏛🏗🧱🪨🪵🛖🏘🏚🏠🏡🏢🏣🏤🏥🏦🏨🏩🏪🏫🏬🏭🏯🏰💒🗼🗽⛪🕌🛕🕍⛩🕋⛲⛺🌁🌃🏙🌄🌅🌆🌇🌉♨🎠🛝🎡🎢💈🎪🛹🛼🚏🛣🛤🛢⛽🛞🚨🚥🚦🛑🚧⚓🛟⛵🛶🚤🛳⛴🛥🚢✈🛩🛫🛬🪂💺🚁🚟🚠🚡🛰🚀🛸🛎🧳⌛⏳⌚⏰⏱⏲🕰🕛🕧🕐🕜🕑🕝🕒🕞🕓🕟🕔🕠🕕🕡🕖🕢🕗🕣🕘🕤🕙🕥🕚🕦🌑🌒🌓🌔🌕🌖🌗🌘🌙🌚🌛🌜🌡☀🌝🌞🪐⭐🌟🌠🌌☁⛅⛈🌤🌥🌦🌧🌨🌩🌪🌫🌬🌀🌈🌂☂☔⛱⚡❄☃⛄☄🔥💧🌊',
  },
  {
    title: '食物',
    type: 'emoji',
    content:
      '🍇🍈🍉🍊🍋🍌🍍🥭🍎🍏🍐🍑🍒🍓🫐🥝🍅🫒🥥🥑🍆🥔🥕🌽🌶🫑🥒🥬🥦🧄🧅🍄🥜🫘🌰🍞🥐🥖🫓🥨🥯🥞🧇🧀🍖🍗🥩🥓🍔🍟🍕🌭🥪🌮🌯🫔🥙🧆🥚🍳🥘🍲🫕🥣🥗🍿🧈🧂🥫🍱🍘🍙🍚🍛🍜🍝🍠🍢🍣🍤🍥🥮🍡🥟🥠🥡🦀🦞🦐🦑🦪🍦🍧🍨🍩🍪🎂🍰🧁🥧🍫🍬🍭🍮🍯🍼🥛☕🫖🍵🍶🍾🍷🍸🍹🍺🍻🥂🥃🫗🥤🧋🧃🧉🧊🥢🍽🍴🥄🔪🫙🏺',
  },
  {
    title: '物体',
    type: 'emoji',
    content:
      '👓🕶🥽🥼🦺👔👕👖🧣🧤🧥🧦👗👘🥻🩱🩲🩳👙👚👛👜👝🛍🎒🩴👞👟🥾🥿👠👡🩰👢👑👒🎩🎓🧢🪖⛑📿💄💍💎🔇🔈🔉🔊📢📣📯🔔🔕🎼🎵🎶🎙🎚🎛🎤🎧📻🎷🪗🎸🎹🎺🎻🪕🥁🪘📱📲☎📞📟📠🔋🪫🔌💻🖥🖨⌨🖱🖲💽💾💿📀🧮🎥🎞📽🎬📺📷📸📹📼🔍🔎🕯💡🔦🏮🪔📔📕📖📗📘📙📚📓📒📃📜📄📰🗞📑🔖🏷💰🪙💴💵💶💷💸💳🧾💹✉📧📨📩📤📥📦📫📪📬📭📮🗳✏✒🖋🖊🖌🖍📝💼📁📂🗂📅📆🗒🗓📇📈📉📊📋📌📍📎🖇📏📐✂🗃🗄🗑🔒🔓🔏🔐🔑🗝🔨🪓⛏⚒🛠🗡⚔🔫🪃🏹🛡🪚🔧🪛🔩⚙🗜⚖🦯🔗⛓🪝🧰🧲🪜⚗🧪🧫🧬🔬🔭📡💉🩸💊🩹🩼🩺🩻🚪🛗🪞🪟🛏🛋🪑🚽🪠🚿🛁🪤🪒🧴🧷🧹🧺🧻🪣🧼🫧🪥🧽🧯🛒🚬⚰🪦⚱🗿🪧🪪',
  },
  {
    title: '活动',
    type: 'emoji',
    content:
      '🎃🎄🎆🎇🧨✨🎈🎉🎊🎋🎍🎎🎏🎐🎑🧧🎀🎁🎗🎟🎫🎖🏆🏅🥇🥈🥉⚽⚾🥎🏀🏐🏈🏉🎾🥏🎳🏏🏑🏒🥍🏓🏸🥊🥋🥅⛳⛸🎣🤿🎽🎿🛷🥌🎯🪀🪁🎱🔮🪄🧿🪬🎮🕹🎰🎲🧩🧸🪅🪩🪆♠♥♦♣♟🃏🀄🎴🎭🖼🎨🧵🪡🧶🪢',
  },
  {
    title: '自然',
    type: 'emoji',
    content:
      '🙈🙉🙊🐵🐒🦍🦧🐶🐕🦮🐕🐩🐺🦊🦝🐱🐈🐈🦁🐯🐅🐆🐴🐎🦄🦓🦌🦬🐮🐂🐃🐄🐷🐖🐗🐽🐏🐑🐐🐪🐫🦙🦒🐘🦣🦏🦛🐭🐁🐀🐹🐰🐇🐿🦫🦔🦇🐻🐻🐨🐼🦥🦦🦨🦘🦡🐾🦃🐔🐓🐣🐤🐥🐦🐧🕊🦅🦆🦢🦉🦤🪶🦩🦚🦜🐸🐊🐢🦎🐍🐲🐉🦕🦖🐳🐋🐬🦭🐟🐠🐡🦈🐙🐚🪸🐌🦋🐛🐜🐝🪲🐞🦗🪳🕷🕸🦂🦟🪰🪱🦠💐🌸💮🪷🏵🌹🥀🌺🌻🌼🌷🌱🪴🌲🌳🌴🌵🌾🌿☘🍀🍁🍂🍃🪹🪺',
  },
];

export const SELLPOINT_RULES = {
  principalId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
  seriesId: [{ required: true, message: '请选择车系', trigger: 'change' }],
  sellId: [{ required: true, message: '请选择卖点', trigger: 'change' }],
  desc: [{ required: true, message: '请输入描述', trigger: 'blur' }],
};

export const WEEK_RENDER_LIST = [
  {
    text: '周一',
    date: '',
    shortDate: '',
  },
  {
    text: '周二',
    date: '',
    shortDate: '',
  },
  {
    text: '周三',
    date: '',
    shortDate: '',
  },
  {
    text: '周四',
    date: '',
    shortDate: '',
  },
  {
    text: '周五',
    date: '',
    shortDate: '',
  },
  {
    text: '周六',
    date: '',
    shortDate: '',
  },
  {
    text: '周日',
    date: '',
    shortDate: '',
  },
];

export const personalityTypeList = [
  {
    label: '销售',
    value: 'SALE',
  },
  {
    label: '门店',
    value: 'STORE',
  },
];

export const personalityTypeObject = personalityTypeList.reduce((pre, cur) => ((pre[cur.value] = cur.label), pre), {});

export const TIME_TYPE_LIST = [
  {
    label: '入库时间',
    value: 'ctime',
  },
  {
    label: '发布时间',
    value: 'publish_time',
  },
  {
    label: '编辑时间',
    value: 'last_update_time',
  },
  {
    label: '数据更新时间',
    value: 'mtime',
  },
];

export const directionList = [
  { name: '种草', value: 'RECOMMEND' },
  { name: '营销报价', value: 'QUOTED_PRICE' },
  { name: '干货', value: 'SUBSTANCE' },
];
export const sellTypeList = [
  { name: '详细', value: 'DETAILED' },
  { name: '简略', value: 'SIMPLE' },
];
export const sellingColumns = [
  {
    title: '核心卖点',
    width: '20%',
    align: 'center',
    scopedSlots: { customRender: 'coreSellingPoint' },
  },
  {
    title: '产品或服务',
    width: '30%',
    align: 'center',
    scopedSlots: { customRender: 'productFeature' },
  },
  {
    title: '描述',
    scopedSlots: { customRender: 'productDescription' },
  },
];
export const activityColumns = [
  {
    title: '优惠范围',
    align: 'center',
    width: '15%',
    // ellipsis: true,
    scopedSlots: { customRender: 'dealerArr' },
  },
  {
    title: '活动名称',
    width: '15%',
    scopedSlots: { customRender: 'promotionName' },
  },
  {
    title: '描述',
    scopedSlots: { customRender: 'promotionDescription' },
  },
  {
    title: '起始时间',
    width: '15%',
    scopedSlots: { customRender: 'promotionStartTime' },
  },
  {
    title: '截止时间',
    width: '15%',
    scopedSlots: { customRender: 'promotionEndTime' },
  },
];
export const detailDrawerColumns = [
  {
    title: '核心卖点',
    dataIndex: 'coreSellingPoint',
    width: '20%',
    align: 'center',
  },
  {
    title: '产品或服务',
    width: '30%',
    align: 'center',
    dataIndex: 'productFeature',
  },
  {
    title: '优惠范围',
    align: 'center',
    width: '23%',
    dataIndex: 'dealerArrString',
    scopedSlots: { customRender: 'dealerArrString' },
  },
  {
    title: '活动名称',
    width: '15%',
    dataIndex: 'promotionName',
  },
  {
    title: '起始时间',
    dataIndex: 'promotionStartTime',
  },
  {
    title: '截止时间',
    dataIndex: 'promotionEndTime',
  },
  {
    title: '描述',
    dataIndex: 'productDescription',
  },
  {
    title: '描述',
    dataIndex: 'promotionDescription',
  },
];
export const recommendDefaultList = ['情绪营销', '产品卖点', '产品报价（无金额）'];
export const quotedDefaultList = ['情绪营销', '优惠活动', '产品报价（有金额）'];
export const peopleList = ['宝妈', '宝爸', '小家庭', '年轻人'];
