<template>
  <div>
    <a-form-model
      ref="searchForm"
      :model="searchForm"
      layout="horizontal"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row type="flex" justify="space-between">
        <a-col :span="5">
          <a-form-model-item label="品牌">
            <a-select
              mode="multiple"
              v-model="searchForm.principalId"
              option-filter-prop="children"
              show-search
              placeholder="请选择品牌"
              :maxTagCount="2"
              allowClear
              @change="handleSearchPrincipalChange"
            >
              <a-select-option v-for="item in principalList" :key="item.value" :value="item.value">{{
                item.label
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="车系">
            <a-select
              mode="multiple"
              :loading="seriesLoading"
              v-model="searchForm.seriesId"
              placeholder="请选择车系"
              show-search
              option-filter-prop="children"
              :maxTagCount="2"
              allowClear
            >
              <a-select-option v-for="item in seriesList" :key="item.id">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="卖点">
            <a-select class="common-item" v-model="searchForm.sellId" allowClear placeholder="请选择">
              <a-select-option v-for="item in sellingPointsList" :key="item.featureCode" :value="item.featureCode">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="具体卖点">
            <a-input v-model.trim="searchForm.thdFeature" :placeholder="'请输入'" allowClear class="common-item" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="data-table">
      <!-- <a-button style="margin: 20px 0" type="primary" icon="plus" @click="visible = true"> 新建 </a-button> -->
      <div style="display: flex; align-items: center; justify-content: space-between; margin: 10px 0">
        <div>卖点描述条数 {{ totalFeatures }}</div>
        <div>
          <a-space>
            <span style="color: #858585">更新后通知研发同步</span>
            <a-button type="primary" icon="link" @click="handleJump">在线维护表格</a-button>
            <a-button type="primary" icon="search" @click="onSearch"> 查询 </a-button>
            <a-button icon="redo" @click="onReset"> 重置 </a-button>
          </a-space>
        </div>
      </div>
      <a-table :loading="loading" :columns="SELL_COLUMNS" :data-source="tableData" :pagination="false" row-key="id">
        <template slot="principalId" slot-scope="text, row">
          <div>{{ row.principalName }}</div>
        </template>
        <template slot="seriesId" slot-scope="text, row">
          <div>{{ row.seriesName }}</div>
        </template>
        <template slot="featureCode" slot-scope="text, row">
          <div>{{ text }}</div>
        </template>
        <template slot="thdFeature" slot-scope="text, row">
          <div>{{ text }}</div>
        </template>
        <template slot="featureDesc" slot-scope="text, row">
          <div class="feature_class" :title="text">{{ text }}</div>
        </template>
        <template slot="ctime" slot-scope="text, row">
          <div>{{ row.ctime.replace('T', ' ') }}</div>
        </template>
      </a-table>
      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>
    <!-- 新建 -->
    <a-modal
      :visible="visible"
      :maskClosable="false"
      :confirmLoading="loadingModal"
      @cancel="handleVisible"
      @ok="handleModalOk"
    >
      <template #title>
        <div>
          <span>{{ currentId ? '编辑' : '新建' }}卖点</span>
        </div>
      </template>
      <a-form-model
        layout="horizontal"
        ref="form"
        :model="modalForm"
        :rules="SELLPOINT_RULES"
        :label-col="labelColModal"
        :wrapper-col="wrapperColModal"
      >
        <a-form-model-item label="品牌" prop="principalId">
          <a-select
            class="common-item"
            v-model="modalForm.principalId"
            option-filter-prop="children"
            show-search
            placeholder="请选择"
            allowClear
            @change="handleModalPrincipalChange"
          >
            <a-select-option v-for="item in principalList" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车系" prop="seriesId">
          <a-select
            class="common-item"
            :loading="seriesLoading"
            v-model="modalForm.seriesId"
            option-filter-prop="children"
            show-search
            placeholder="请选择"
            allowClear
          >
            <a-select-option v-for="item in modalSeriesList" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="卖点" prop="sellId">
          <a-select
            class="common-item"
            v-model="modalForm.sellId"
            option-filter-prop="children"
            show-search
            placeholder="请选择"
            allowClear
          >
            <a-select-option v-for="item in sellingPointsList" :key="item.value" :value="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="描述" prop="desc">
          <a-textarea v-model.trim="modalForm.desc" placeholder="请输入车系卖点描述" allowClear class="common-item" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
// import base from "@/api/base";
// import base_api from "@/api/base_api";
import { SELL_COLUMNS, SELLPOINT_RULES } from './constant';
import aigcAPI from '@/api/xhsAgencyApi';
// import { removeDuplicatesByField } from "@/utils";

export default {
  name: 'aigcSellingPoints',
  data() {
    return {
      userName: this.$router.app.user.username,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      searchForm: {
        principalId: undefined,
        seriesId: undefined,
        sellId: undefined,
        thdFeature: '',
      },
      sellingPointsList: [],
      principalList: [],
      seriesList: [],
      loading: false,
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      currentRow: {},
      visible: false,
      loadingModal: false,
      currentId: '',
      modalSeriesList: [],
      modalSellList: [],
      modalForm: {
        principalId: undefined,
        seriesId: undefined,
        sellId: undefined,
        desc: '',
      },
      labelColModal: { span: 4 },
      wrapperColModal: { span: 20 },
      brandSeriesList: [],
      seriesLoading: false,
      totalFeatures: 0,
    };
  },
  computed: {
    SELL_COLUMNS: () => SELL_COLUMNS,
    SELLPOINT_RULES: () => SELLPOINT_RULES,
    modalPrincipalName() {
      return this.principalList.find((item) => item.value === this.modalForm.principalId)?.label || '';
    },
    modalSeriesName() {
      return this.modalSeriesList.find((item) => item.id === this.modalForm.seriesId)?.name || '';
    },
  },
  components: {},
  mounted() {
    this.fetchPrincipalList();
    this.getFeatureList();
    this.getTableData();
  },
  methods: {
    async fetchPrincipalList() {
      aigcAPI.getBrandList().then((res) => {
        this.principalList = res.data.map((item) => {
          return { label: item.principalName, value: item.id };
        });
      });
    },
    async handleSearchPrincipalChange(id) {
      this.searchForm.seriesId = undefined;
      if (!id.length) return (this.seriesList = []);
      const principalIds =
        id.reduce((prev, item) => {
          prev += `,${item}`;
          return prev;
        }) || '';
      principalIds && (await this.getSeriesList(principalIds, 'seriesList'));
    },
    handleFeatures(text) {
      return this.sellingPointsList.find((item) => item.value === text)?.name || '';
    },
    async fetchPrincipalList() {
      aigcAPI.getBrandList().then((res) => {
        this.principalList = res.data.map((item) => {
          return { label: item.principalName, value: item.id };
        });
      });
    },
    getFeatureList() {
      aigcAPI.getFeatureCodeList().then(({ code, data, message }) => {
        if (code === 200) {
          this.sellingPointsList = data;
        } else {
          this.$message.error(`${message}`);
        }
      });
    },
    getTableData() {
      this.loading = true;
      const params = {
        principalIds: this.searchForm.principalId,
        carSeriesIds: this.searchForm.seriesId,
        featureCode: this.searchForm.sellId,
        thdFeature: this.searchForm.thdFeature,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      aigcAPI
        .getFeatureInfoList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.tableData = data?.list;
            this.totalFeatures = data.total;
            this.pagination.current = data?.page;
            this.pagination.total = data?.total;
          }else{
            this.$message.error(`${message}`)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSearch() {
      this.pagination.current = 1;
      this.getTableData();
    },
    onReset() {
      this.searchForm = {
        principalId: undefined,
        seriesId: undefined,
        sellId: undefined,
        thdFeature: '',
      };
      this.seriesList = [];
      this.pagination.current = 1;
      this.getTableData();
    },
    async handlePrincipalChange(id) {
      this.searchForm.seriesId = undefined;
      await this.getSeriesList(id, 'seriesList');
    },
    getSeriesList(principalIds, dataStr) {
      this[dataStr] = [];
      if (!principalIds) return;
      this.seriesLoading = true;
      aigcAPI
        .getTypeList({ principalIds })
        .then((res) => {
          this[dataStr] = res.data.map((val) => ({ id: val.id, name: val.name }));
        })
        .finally(() => (this.seriesLoading = false));
    },
    async handleModalPrincipalChange(id) {
      this.modalForm.seriesId = undefined;
      await this.getSeriesList(id, 'modalSeriesList');
    },
    handleVisible() {
      this.visible = !this.visible;
      if (!this.visible) {
        this.resetModalForm();
      }
    },
    handleModalOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingModal = true;
          const params = {
            creator: this.userName,
            principalId: this.modalForm.principalId,
            principalName: this.modalPrincipalName,
            seriesId: this.modalForm.seriesId,
            seriesName: this.modalSeriesName,
            modifier: this.userName,
            featureCode: this.modalForm.sellId,
            featureDesc: this.modalForm.desc,
          };
          if (this.currentId) {
            params.seriesFeatureId = this.currentId;
            // 编辑改为当前行数据的creator
            params.creator = this.currentRow.creator;
            aigcAPI
              .updateAigcXhsSeriesFeature(params)
              .then(() => {
                this.$message.success('编辑卖点成功');
                this.handleVisible();
                this.getTableData();
              })
              .finally(() => {
                this.loadingModal = false;
              });
          } else {
            aigcAPI
              .newAigcSeriesFeature(params)
              .then(() => {
                this.$message.success('新建卖点成功');
                this.handleVisible();
                this.onReset();
              })
              .finally(() => {
                this.loadingModal = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    resetModalForm() {
      this.modalForm = {
        principalId: undefined,
        seriesId: undefined,
        sellId: undefined,
        thdFeature: '',
      };
      this.$refs.form.resetFields();
      this.currentId = '';
      this.modalSeriesList = [];
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTableData();
    },
    handleEdit(row) {
      this.modalForm = {
        principalId: row.principalId,
        seriesId: row.seriesId,
        sellId: row.featureCode,
        desc: row.featureDesc,
      };
      this.getSeriesList(row.principalId, 'modalSeriesList');
      this.currentId = row.id;
      this.currentRow = row;
      this.handleVisible();
    },
    handleRemove(row) {
      this.$confirm({
        title: '确定删除该卖点',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          const params = {
            isDeleted: 1,
            principalId: row.principalId,
            principalName: row.principalName,
            seriesId: row.seriesId,
            seriesName: row.seriesName,
            modifier: row.modifier,
            featureCode: row.featureCode,
            featureDesc: row.featureDesc,
            seriesFeatureId: row.id,
          };
          aigcAPI
            .updateAigcXhsSeriesFeature(params)
            .then(() => {
              this.$message.success('删除卖点成功');
              this.getTableData();
            })
            .finally(() => {
              this.loadingModal = false;
            });
        },
        onCancel: () => {},
      });
    },
    handleJump() {
      const url = 'http://doc.weixin.qq.com/sheet/e3_AW8AsAY3AEEJP8q7L53QjWCTvsKl1?scode=ACEAcQe6AA4HA9KqpuAW8AsAY3AEE';
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped lang="less">
.feature_class {
  max-width: 500px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
